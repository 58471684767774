export const ROOT = '/';
export const LEADS = '/leads';
export const LEAD_DETAILS = '/leads/:id';
export const LOGIN = '/auth/login';
export const LOGOUT = '/logout';

export default {
  ROOT,
  LEADS,
  LEAD_DETAILS,
  LOGIN,
  LOGOUT,
};
