import { createBrowserRouter /* redirect */ } from 'react-router-dom';
import routes from './constants/routes';

export default createBrowserRouter(
  [
    {
      path: routes.ROOT,
      lazy: () => import('./layouts/RootLayout'),
      children: [
        {
          lazy: () => import('./layouts/DashboardLayout'),
          children: [
            {
              index: true,
              lazy: () => import('./pages/DashboardPage'),
            },
            {
              path: routes.LEADS,
              lazy: () => import('./pages/LeadsPage'),
            },
            {
              path: routes.LEAD_DETAILS,
              lazy: () => import('./pages/LeadDetailsPage'),
            },
            {
              path: routes.LOGOUT,
              lazy: () => import('./pages/LogoutPage'),
            },
            {
              path: '*',
              lazy: () => import('./pages/NoMatchPage'),
            },
          ],
        },

        {
          lazy: () => import('./layouts/AuthLayout'),
          children: [
            {
              index: true,
              path: routes.LOGIN,
              lazy: () => import('./pages/LoginPage'),
            },
          ],
        },
        {
          path: '*',
          lazy: () => import('./pages/NoMatchPage'),
        },
      ],
    },
  ],
);
